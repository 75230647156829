<template>
  <div class="contact">
    <div class="font-maxime text-4xl tracking-widest text-white text-center">
      {{ $t('contactMe.title') }}
    </div>
    <div class="mt-4 grid grid-cols-3">
      <div />
      <form ref="form" @submit.prevent="sendEmail" v-if="!send">
        <div class="mb-6">
          <label for="user_name" class="text-center block mb-2 text-sm font-medium text-white">{{ $t('contactMe.name') }}</label>
          <input type="text" id="user_name" name="user_name" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500" :placeholder="$t('contactMe.namePlaceholder')" required>
        </div>
        <div class="mb-6">
          <label for="email" class="text-center block mb-2 text-sm font-medium text-white">{{ $t('contactMe.email') }}</label>
          <input type="email" id="user_email" name="user_email" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500" placeholder="mail@gmail.com" required>
        </div>
        <div class="mb-6">
          <label for="message" class="text-center block mb-2 text-sm font-medium text-white">{{ $t('contactMe.message') }}</label>
          <textarea id="message" name="message" rows="4" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500" :placeholder="$t('contactMe.messagePlaceholder')" required></textarea>
        </div>
        <button type="submit" class="border-2 border-white hover:bg-gray-800 text-white font-bold py-2 px-4">{{ $t('contactMe.send') }}</button>
      </form>
      <div v-else>
        <div class="text-center block mb-2 text-sm font-medium text-white">
          {{ $t('contactMe.sendSuccess') }}
        </div>
      </div>
      <div />
    </div>
  </div>
</template>

<script>

import emailjs from '@emailjs/browser';

export default {
  data: function(){
    return {
      emai: null,
      message: null,
      send: false,
    };
  },
  methods: {
    sendEmail() {
      console.log(process.env);
      emailjs.sendForm(process.env.VUE_APP_MAIL_SERVICE, process.env.VUE_APP_MAIL_TEMPLATE, this.$refs.form, process.env.VUE_APP_MAIL_PUBLIC_KEY)
        .then(() => {
            this.send = true;
        }, (error) => {
            console.log('FAILED...', error.text);
        });
    }
  }
}
</script>
